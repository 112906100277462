let $ = jQuery;

class Files {
    private items: FileGroup[];
    private $el: JQuery;
    private nix: number = 1;

    public constructor($el: JQuery) {
        this.items = [];

        this.$el = $el;

        this.initHtml();
    }

    public getNix(): number {
        return this.nix;
    }

    public addGroup(group: FileGroup): void {
        this.nix++;

        let $group = group.getEl();
        $group.hide();

        this.items.push(group);
        this.$el.append($group);

        $group.slideDown();
    }

    public removeGroup(group: FileGroup): void {
        let $el = group.getEl();

        $el.slideUp(200, () => {
            $el.remove();
        });

        let result = [];

        $.each(this.items, (ix, v) => {
            result.push(v);
        });

        this.items = result;
    }

    public initHtml(): void {
        this.$el.sortable({
            handle: '.pageeditor__drag'
        });
    }
}

class FileGroup {
    private files: Files;

    private $el: JQuery;
    private $title: JQuery;
    private $content: JQuery;
    private $items: JQuery;

    private id: string;
    private isnew: boolean;

    private nix: number = 1;

    private items: FileItem[];

    public constructor(files: Files, id: string, isnew: boolean) {
        this.items = [];

        this.files = files;

        this.id = id;
        this.isnew = isnew;

        this.initHtml();
    }

    public getId(): string {
        return this.id;
    }

    public getTitle(): string {
        return this.$title.val();
    }

    public setTitle(title: string): void {
        this.$title.val(title);
    }

    public getContent(): string {
        return this.$content.val();
    }

    public setContent(content: string): void {
        this.$content.val(content);
    }

    public getEl(): JQuery {
        return this.$el;
    }

    public isNew(): boolean {
        return this.isnew;
    }

    public addItem(item: FileItem): void {
        this.nix++;

        const $item = item.getEl();

        this.items.push(item);
        this.$items.append($item);
    }

    public removeItem(item: FileItem): void {
        let $el = item.getEl();

        $el.slideUp(200, () => {
            $el.remove();
        });

        let result = [];

        $.each(this.items, (ix, v) => {
            result.push(v);
        });

        this.items = result;
    }

    private initHtml(): void {
        const prefix = 'group_' + this.getId();

        let remove: string;

        if (this.isNew()) {
            remove = `
                <button type="button">usuń</button>
            `;
        } else {
            remove = `
                <label>
                    <input type="checkbox" name="` + prefix + `_remove"> usuń
                </label>
            `;
        }

        this.$el = $(`
            <div class="pageeditor__files-group">
                <div class="pageeditor__files-group-drag pageeditor__drag" title="Zmień kolejność">
                    <img src="/theme/images/icons/drag.svg" alt="Zmień kolejność">
                </div>

                <div class="pageeditor__files-group-header">
                    <div class="pageeditor__files-group-title">
                        <input type="text" name="` + prefix + `_title" value="" placeholder="Nazwa kategorii" aria-label="Nazwa kategorii">
                    </div>

                    <div class="pageeditor__files-group-remove">
                        ` + remove + `
                    </div>

                    <button type="button" class="pageeditor__files-group-expand" value="Rozwiń/zwiń">+</button>
                </div>

                <div class="pageeditor__files-group-content">
                    <textarea name="` + prefix + `_content" placeholder="Treść kategorii"></textarea>
                </div>

                <div class="pageeditor__files-group-list"></div>

                <div class="pageeditor__files-group-footer">
                    <button type="button" class="button button--2 pageeditor__files-group-additem">Dodaj załącznik</button>
                </div>
            </div>
        `);

        this.$title = $('.pageeditor__files-group-title input', this.$el);
        this.$content = $('.pageeditor__files-group-content textarea', this.$el);

        this.$items = $('.pageeditor__files-group-list', this.$el);

        const $drag = $('.pageeditor__files-group-drag', this.$el);
        const $expand = $('.pageeditor__files-group-expand', this.$el);

        let expanded = false;

        $expand.on('click', (e) => {
            e.preventDefault();

            this.items.forEach((fi) => {
                fi.expand(!expanded);
            });

            expanded = !expanded;

            $expand.text(expanded ? '-' : '+');
        });

        const $add = $('.pageeditor__files-group-additem', this.$el);

        const $remove = $('.pageeditor__files-group-remove button', this.$el);

        $remove.on('click', (e) => {
            e.preventDefault();

            this.files.removeGroup(this);
        })

        $add.on('click', (e) => {
            e.preventDefault();

            let file = new FileItem(this, this.getId() + '_n' + this.nix, null, true);
            file.initHtml();

            this.addItem(file);
        });

        this.$items.sortable({
            handle: '.pageeditor__drag'
        });
    }
}

class FileItem {
    private $el: JQuery;
    private $title: JQuery;
    private $expander: JQuery;
    private expanded: boolean;
    private $more: JQuery;
    private $iremove: JQuery;

    private group: FileGroup;

    private id: string;
    private title: string;
    private isnew: boolean;
    private toremove: boolean;

    public field_author: string  = '';
    public field_warder: string  = '';
    public field_creator: string = '';
    public field_publisher: string = '';
    public field_date: string = '';
    public field_changes: string = '';

    public constructor(group: FileGroup, id: string, title: string, isnew: boolean) {
        this.group = group;

        this.id = id;
        this.title = title;
        this.isnew = isnew;
        this.toremove = false;
        this.expanded = false;
    }

    public getId(): string {
        return this.id;
    }

    public getEl(): JQuery {
        return this.$el;
    }

    public getTitle(): string {
        return this.title;
    }

    public isNew(): boolean {
        return this.isnew;
    }

    public isToRemove(): boolean {
        return this.toremove;
    }

    public setToRemove(toremove: boolean): void {
        this.toremove = toremove;

        this.$iremove.prop('checked', toremove ? 'checked' : '');
    }

    public expand(expand?: boolean): void {
        if (expand != null) {
            this.expanded = expand;
        } else {
            this.expanded = !this.expanded;
        }

        if (this.expanded) {
            this.$more.slideDown();
        } else {
            this.$more.slideUp();
        }

        this.$expander.text(this.expanded ? '-' : '+');
    }

    public initHtml(): void {
        const prefix = 'file_' + this.getId();

        let uploader: string;
        let remove: string;

        if (this.isNew()) {
            uploader = `
                <input type="file" name="` + prefix + `_file" required aria-label="Wybierz plik">
            `;

            remove = `
                <button type="button">usuń</button>
            `;
        } else {
            uploader = `
                <div class="pageeditor__files-item-browse-info">Wgraj nową wersję pliku:</div>
                <input type="file" name="` + prefix + `_file" aria-label="Wybierz nową wersję pliku">
            `;

            remove = `
                <label>
                    <input type="checkbox" name="` + prefix + `_remove" aria-label="Usuń plik"> usuń
                </label>
            `;
        }

        this.$el = $(`
            <div class="pageeditor__files-item">
                <div class="pageeditor__files-item-drag pageeditor__drag" title="Zmień kolejność">
                    <img src="/theme/images/icons/drag.svg" alt="Zmień kolejność">
                </div>

                <div class="pageeditor__files-item-inner">
                    <div class="pageeditor__files-item-main">
                        <div class="pageeditor__files-item-icon"><i class="fa fa-file-alt"></i></div>

                        <div class="pageeditor__files-item-title">
                            <input type="text" name="` + prefix + `_title" placeholder="Nazwa załącznika" aria-label="Nazwa załącznika" required>
                        </div>

                        <div class="pageeditor__files-item-browse">
                            ` + uploader + `
                        </div>

                        <div class="pageeditor__files-item-remove">
                            ` + remove + `
                        </div>
                    </div>

                    <div class="pageeditor__files-item-more">
                        <div class="pageeditor__files-item-more-inner">
                            <div class="pageeditor__files-item-more-groups">
                                <div class="pageeditor__files-item-more-group pageeditor__files-item-more-group--first">
                                    <div><span>Autor:</span> <input type="text" name="` + prefix + `_author" value="` + this.field_author + `" aria-label="Autor"></div>
                                    <div><span>Odpowiada:</span> <input type="text" name="` + prefix + `_warder" value="` + this.field_warder + `" aria-label="Odpowiada"></div>
                                    <div><span>Wytworzył:</span> <input type="text" name="` + prefix + `_creator" value="` + this.field_creator + `" aria-label="Wytworzył"></div>
                                    <div><span>Opublikował:</span> <input type="text" name="` + prefix + `_publisher" value="` + this.field_publisher + `" aria-label="Opublikował"></div>
                                    <div><span>Data:</span> <input type="text" name="` + prefix + `_date" value="` + this.field_date + `" placeholder="np. 2019-01-29" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" aria-label="Data"></div>
                                </div>

                                <div class="pageeditor__files-item-more-group pageeditor__files-item-more-group--text">
                                    <span>Opis zmian:</span> <textarea name="` + prefix + `_changes">` + this.field_changes + `</textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" class="pageeditor__files-item-expand" value="Rozwiń/zwiń">+</button>
            </div>
        `);

        this.$title = $('.pageeditor__files-item-title input', this.$el);
        this.$title.val(this.getTitle());

        this.$more = $('.pageeditor__files-item-more', this.$el);
        this.$expander = $('.pageeditor__files-item-expand', this.$el);

        this.$expander.on('click', (e) => {
            e.preventDefault();
            this.expand();
        });

        this.$iremove = $('.pageeditor__files-item-remove input', this.$el);

        const $file = $('.pageeditor__files-item-browse input', this.$el);

        $file.on('change', (e) => {
            let name = this.$title.val();

            if (name == null || name.length == 0) {
                let filename = $file.val().match(/[^\/\\]+$/);
                this.$title.val(filename);
            }
        });

        const $remove = $('.pageeditor__files-item-remove button', this.$el);

        $remove.on('click', (e) => {
            e.preventDefault();

            this.group.removeItem(this);
        });
    }
}

export default class Editor {
    private files: Files;
    private $form: JQuery;

    public constructor() {
        this.initHtml();
    }

    private initHtml(): void {
        this.files = new Files($('.pageeditor__files-widget'));

        $('.pageeditor__files-creategroup').click((e) => {
            const gc = this.files.getNix();
            const gid = 'n' + gc.toString();

            let group = new FileGroup(this.files, gid, true);

            let file = new FileItem(group, gid + '_n1', null, true);
            group.addItem(file);

            this.files.addGroup(group);

            e.preventDefault();
        });
    }

    public loadJson(data: any): void {
        const groups = data;

        for (const gid in groups) {
            if (groups.hasOwnProperty(gid)) {
                const jgroup = groups[gid];

                let group = new FileGroup(this.files, gid, jgroup.new);
                group.setTitle(jgroup.title);
                group.setContent(jgroup.content);

                let items = jgroup.items;

                for (const iid in items) {
                    if (items.hasOwnProperty(iid)) {
                        const jitem = items[iid];

                        let item = new FileItem(group, gid + '_' + iid, jitem.title, jitem.new);
                        item.field_author = jitem.author ? jitem.author : '';
                        item.field_warder = jitem.warder ? jitem.warder : '';
                        item.field_creator = jitem.creator ? jitem.creator : '';
                        item.field_publisher = jitem.publisher ? jitem.publisher : '';
                        item.field_date = jitem.date ? jitem.date : '';
                        item.field_changes = jitem.changes ? jitem.changes : '';
                        item.initHtml();

                        item.setToRemove(jitem.toremove);

                        group.addItem(item);
                    }
                }

                this.files.addGroup(group);
            }
        }
    }
}
